<template>
  <div class="about">
    <h1>A site for events to better the world.</h1>
  </div>
</template>

<script>
export default {
  name: "About"}
</script>
