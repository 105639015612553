<template>
  <div v-if="event">
    <h1>{{ event.title }}</h1>
    <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
    <p>{{ event.description }}</p>
    <!-- <span>Event # {{$route.params.id}}</span> pour avoir acces aux paramétre de la route (l'id)-->
  </div>
</template>

<script>
import EventService from '@/services/EventService.js';
export default {
  props: ['id'],
  data() {
    return {
      event: null
    }
  },
  created() {
    // EventService.getEvent(this.$route.params.id) pour avoir accés aux paramétres de la route sans le faire passer en props
    EventService.getEvent(this.id)
      .then(response => {
        this.event = response.data
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>
